$rs: 16px; /** This is the Root Font Size **/


/** Colors **/

$color-body-font: #202020;
$color-link-hover: #DD0000;
$color-link-sidebar: #AAA;
$color-black: #111;
$color-black-light: #666;
$color-black-lighter: #999;
$color-white: #fff;
$color-white-light: #f5f5f5;
$color-white-dark: #ccc;
$color-bg-sub: #ccc;
$color-main: #F8CA00;
$color-main-light: #f5e395;
$color-main-dark: #E97F02;
$color-secondary: #BD1550;
$color-secondary-light: #e5a3ba;
$color-secondary-lighter: #f2d2dd;
$color-secondary-dark: #490A3D;
$color-border: #BABCBD;
$color-border-lighter: #E5E5E5;

/** Fonts **/

$font-serif: 'Andada Pro', Georgia, serif;
$font-sans: 'Lato', sans-serif;

:root {
    --text-xs: clamp(0.70rem, calc(0.79rem + -0.06vw), 0.78rem);
    --text-s: clamp(0.94rem, calc(0.94rem + 0.00vw), 0.94rem);
    --text-m: clamp(1.13rem, calc(1.11rem + 0.09vw), 1.25rem);
    --text-l: clamp(1.35rem, calc(1.30rem + 0.23vw), 1.67rem);
    --text-xl: clamp(1.62rem, calc(1.53rem + 0.43vw), 2.22rem);
    --text-xxl: clamp(1.94rem, calc(1.80rem + 0.73vw), 2.96rem);
    --text-jumbo: clamp(2.33rem, calc(2.10rem + 1.15vw), 4rem);
    --text-weight-bold: 600;
    --text-weight-normal: 400;
    --text-leading-wide: 1.6em;
    --text-leading-normal: 1.4em;
    --text-leading-narrow: 1.2em;
    --padding-normal: 1.2rem;
    --padding-broad: 2rem;
    --padding-thin: 0.8rem;
}


/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.cf:before,
.cf:after {
		content: " "; /* 1 */
		display: table; /* 2 */
}

.cf:after {
		clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
		*zoom: 1;
}

/* Import the font file with the icons in it */
@font-face {
		font-family: 'icons';
		src:url('../fonts/icons.eot');
		src:url('../fonts/icons.eot?#iefix') format('embedded-opentype'),
				url('../fonts/icons.woff') format('woff'),
				url('../fonts/icons.ttf') format('truetype'),
				url('../fonts/icons.svg#icons') format('svg');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
}

/* Apply these base styles to all icons */
.icons-ghost, .icons-feed, .icons-twitter, .icons-google-plus, .icons-facebook {
		font-family: 'icons';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		text-decoration: none;
}

/* Each icon is created by inserting the corret character into the
	 content of the :before pseudo element. Like a boss. */
.icons-ghost:before {
		content: "\e000";
		color: #999999;
}
.icons-ghost a a:visited {
	color: #999999;
}
.icons-feed:before {
		content: "\e001";
}
.icons-twitter:before {
		content: "\e002";
		font-size: 1.1em;
}
.icons-google-plus:before {
		content: "\e003";
}
.icons-facebook:before {
		content: "\e004";
}
.invokedcode {
	max-width: 18px;
	margin-left: 2px;
}
.audiophone {
	max-height: 13px;
	margin-left: 8px;
}

.older-posts {
	color:#616161;
	margin-left: 10px;
}

.newer-posts {
	color:#616161;
	margin-right: 10px;
}

/* Hides shit */
.hidden {
		text-indent: -9999px;
		visibility: hidden;
		display: none;
}

/* 06. Related Notes */

.related {
    width: 100%;
    min-height: 100px;
    border-radius: 10px;
}

.related h4 {
    color: $color-secondary-dark;
    padding-left: 10px
}

.related-wrapper {
    column-count: 2 !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem 2rem;
}

.related-group {
    width: 95% !important;
    height: 100%;
    cursor: pointer;
    background-color: $color-white-light;
    padding: 16px;
    border-bottom: 1px solid $color-border;

}

.related-group:hover {
    border-bottom: 1px solid $color-link-hover;
    background-color: $color-bg-sub;
}

.related-group a {
    text-decoration: none;
}

.related-group a:hover {
    text-decoration: none;
    color: $color-link-hover;
}

.related-title {
    padding-left: 10px;
    padding-right: 10px;
}

.links-title {
    border-top: 1px solid #f2f2f0;
    border-bottom: none;
    border-left: none;
    border-right: none;
    display: block;
    font-family: $font-sans;
    text-align: center;
    color: $color-link-sidebar;
}


.links-title legend {
    padding: 5px 10px;
}

.excerpt {
    color: $color-body-font !important;
    margin: 0;
    font-size: var(--text-s) !important;
}

.excerpt h1, h6 {
    font-size: var(--text-m);
    line-height: var(--text-leading-normal);
    font-weight: var(--text-weight-bold);
    padding: var(--padding-thin) 0;
}

.excerpt h1:hover {
   color: $color-link-hover;
}
