@import "essentials";

body {
	background-color: $color-white;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	max-width: 810px;
	margin: 0 auto;
	float: none;
	padding: 0;
	font-size: $rs;
	font-family: $font-serif;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-webkit-hyphens: auto;
		-moz-hyphens: auto;
			 hyphens: auto;
	moz-font-feature-settings: "liga=1, dlig=1";
	-ms-font-feature-settings: "liga", "dlig";
	-webkit-font-feature-settings: "liga", "dlig";
	-o-font-feature-settings: "liga", "dlig";
	font-feature-settings: "liga", "dlig";
}

.sidebar {
	height: 20%;
	width: 100%;
	background-size: cover;
	font-family: $font-sans;
	position: relative;
	.sidebar__logo {
		position: absolute;
		top: 10px;
		left: 10px;
		width: 44px;
		height: 44px;
		z-index: 99;
		background-color: $color-black;
		.sidebar__logo__image {
			width: 22px;
			margin: 11px;
		}
	}
	.sidebar__information {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		max-width: 810px;
		box-sizing: border-box;
		padding: $rs $rs $rs $rs;
		a {
			text-decoration: none;
			color: $color-link-sidebar;
			&:hover {
				color: $color-link-hover;
			}
		}
		.sidebar__information__title {
			font-size: $rs*1.5;
			margin: 0;
			padding: 0;
			line-height: $rs*1.5;
			color: $color-black;
		}
		.sidebar__information__description {
			font-size: $rs;
			margin-bottom: $rs * 2;
			padding: 0 0;
			line-height: $rs;
			color: $color-black-light;
			font-weight: normal;
		}
		.sidebar__information__footer {
			padding: 0 0;
			font-size: $rs;
			color: $color-black-lighter;
		}
	}
}

.contentlist {
	width: 100%;
	.contentlist__inner, .contentlist__inner__notes {
		width: 100%;
		max-width: 810px;
		box-sizing: border-box;
		padding: 50px 25px;
	}
	.contentlist__inner__notes {
		padding: 25px;
	}
	.contentlist__header {
		height: $rs*2.5;
		line-height: $rs*2.5;
		border-top: 1px solid #dededc;
		border-bottom: 1px solid #dededc;
		font-size: $rs;
		font-family: $font-sans;
		.pagination {
			line-height: $rs*2.5;
			font-size: $rs*0.25*3;
			color: #b3b3b1;
			text-transform: uppercase;
			width: 50%;
			float: right;
			text-align: right;
		}
		h1 {
			line-height: $rs*2.5;
			font-size: $rs*0.25*3;
			color: #b3b3b1;
			text-transform: uppercase;
			width: 50%;
			float: left;
			margin: 0;
			padding: 0;
		}
	}
	.contentlist__article {
		padding: $rs*2 0;
		display: block;
		border-bottom: 1px solid #f2f2f0;
		position: relative;
		.contentlist__article__headline {
			font-size: $rs*2;
			line-height: $rs*2.2;
			font-weight: 800;
			padding: 0 $rs*4 $rs*0.5 0;
			margin: 0;
			font-family: $font-sans;
			a {
				text-decoration: none;
				color: $color-black;
				&:hover {
					color: $color-main-dark;
				}
			}
		}
		.contentlist__article__userimage {
			position: absolute;
			right: 0;
			margin: $rs*0.25;
			width: $rs*2;
			height: $rs*2;
			overflow: hidden;
			background-color: #f2f2f0;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			border-radius: 100%;
			background-size: 100%;
		}
	}
	.contentlist__article__excerpt {
		p {
			font-size: $rs;
			line-height: $rs*1.5;
			color: $color-black-light;
			margin: 0;
			padding: 0 0 $rs*0.5;
			font-family: $font-serif;
		}
	}
	.contentlist__article__meta {
		font-size: $rs*0.25*3;
		line-height: $rs*1.5;
		color: $color-black-lighter;
		font-family: $font-sans;
	}
}

.contentlist__pagination {
	padding: $rs 0;
	nav {
		display: block;
		text-align: center;
		font-size: $rs*0.25*3;
		line-height: $rs*1.5;
		color: $color-black-lighter;
		font-family: $font-sans;
	}
}

.footer {
	border-top: 1px solid #f2f2f0;
	padding: $rs 0;
	position: relative;
	margin: $rs*4 0 0 0;
	padding: $rs*4 0 $rs;
	line-height: $rs*1.5;
	font-size: $rs*0.25*3;
	color: $color-black-lighter;
	text-align: center;
	font-family: $font-sans;
	a {
		color: $color-black;
	}
}

.site-footer a {
		text-decoration: underline;
}

.site-footer a:hover {
		color: $color-main-dark;
}

.poweredby .icons-ghost {
		font-weight: 700;
		text-decoration: none;
}

.poweredby .icons-ghost:hover {
		text-decoration: none;
}

.poweredby .icons-ghost:before {
		font-size: 0.6rem;
		margin-right: 0.3em;
}

/* The subscribe icon on the footer */
.subscribe {
		width: $rs*2;
		height: $rs*2;
		position: absolute;
		top: -$rs;
		left: 50%;
		margin-left: -$rs;
		border: #f2f2f0 1px solid;
		text-align: center;
		line-height: $rs*2;
		border-radius: 50px;
		background: #fff;
		transition: box-shadow 0.5s;
		color: transparent !important;
}

/* The RSS icon, inserted via icon font */
.subscribe:before {
		color: $color-white-dark;
		font-size: $rs*0.25*3;
		position: absolute;
		top: -1px;
		left: 11px;
		font-weight: bold;
		transition: color 0.5s ease;
}

/* Add a box shadow to on hover */
.subscribe:hover {
		box-shadow: rgba(0,0,0,0.05) 0 0 0 3px;
		transition: box-shadow 0.25s;
}

.subscribe:hover:before {
		color: #50585D;
}

/* CSS tooltip saying "Subscribe!" - initially hidden */
.tooltip {
		opacity:0;
		display: inline-block;
		padding: 4px 8px 5px 8px;
		position:absolute;
		top: -$rs*1.5;
		left: -$rs*2;
		color: rgba(255,255,255,0.9);
		font-size: $rs;
		font-weight: bold;
		line-height: $rs;
		text-align: center;
		background: #50585D;
		border-radius:20px;
		box-shadow: 0 1px 4px rgba(0,0,0,0.1);
		transition: opacity 0.3s ease, top 0.3s ease;
}

/* The little chiclet arrow under the tooltip, pointing down */
.tooltip:after {
		content:"";
		border-width:5px 5px 0 5px;
		border-style:solid;
		border-color: #50585D transparent;
		display:block;
		position:absolute;
		bottom:-4px;
		left:50%;
		margin-left:-5px;
		z-index: 220;
		width:0;
}

/* On hover, show the tooltip! */
.subscribe:hover .tooltip {
		opacity: 1;
		top: -33px;
}

div.entry-content {
	margin-bottom: 1.5em;
}

div.entry-type {
	margin-right: 10px;
	width: 20px;
	margin-bottom: 40px;
}

/** Post **/

.contentpost {

	.contentpost__logo {
		position: absolute;
		top: 10px;
		left: 10px;
		width: 44px;
		height: 44px;
		z-index: 99;
		background-color: $color-black;
		img {
			width: 22px;
			margin: 11px;
		}
	}
	.contentpost__inner {
		max-width: 550px;
		width: 90%;
		margin: 0 auto;
		position: relative;
	}
	.contentpost__sidebar {
		display: none;
	}
	.contentpost__footerbar {
		border-top: 1px $color-white-dark solid;
		padding: $rs*2 0;
		margin: $rs*2 0 0;
		.contentpost__userimage {
			width: $rs*3;
			height: $rs*3;
			border-radius: $rs*3;
			overflow: hidden;
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center;
			float: left;
			margin: 0 $rs 0 0;
		}
		.contentpost__author {
			position: relative;
			padding: 0 0 $rs;
			width: 60%;
			float: left;
			text-align: left;
			h4 {
				padding: 0 0 0;
				margin: 0;
				font-size: $rs;
				line-height: $rs*1.5;
				color: $color-black;
				font-family: $font-sans;
				font-weight: 800;

			}
			p {
				padding: 0;
				margin: 0;
				font-size: $rs*0.125*7;
				line-height: $rs*1.2;
				font-style: italic;
				color: $color-black-lighter;
				font-family: $font-serif;
			}
		}
	}
	.contentpost__sidebar__meta {
		padding: 0 0;
		text-align: right;
		h5 {
			font-size: $rs*0.25*3;
			line-height: $rs*1.5;
			margin: 0;
			padding: 0;
			font-weight: 800;
			color: $color-black;
			font-family: $font-sans;
		}
		time {
			padding: 0;
			margin: 0;
			font-size: $rs*0.25*3;
			line-height: $rs;
			text-align: right;
			color: $color-black-lighter;
			font-family: $font-serif;
		}
	}
	.contentpost__meta {
		font-size: $rs;
		line-height: $rs;
		color: $color-black-lighter;
		font-family: $font-sans;
	}
	.contentpost__headline {
		font-size: $rs*3;
		line-height: $rs*3;
		padding: $rs 0 $rs*2;
		margin: 0;
		font-family: $font-sans;
		letter-spacing: -2px;
		font-weight: 800;
		margin-left: 80px;
	}
	.contentpost__listline {
		font-size: $rs*2;
		line-height: $rs*2.2;
		font-weight: 800;
		padding: 0 $rs*4 $rs*0.5 0;
		margin: 0;
		margin-left: 80px;

		font-family: $font-sans;
		a {
			text-decoration: none;
		}
		a:link, a:visited {
			color:#202020;
		}
		a:hover, a:link:hover, a:visited:hover {
			color: #666;
		}
	}
	.contentpost__linkline {
		font-size: $rs*1.8;
		line-height: $rs*2;
		font-weight: 600;
		padding: 0 0 $rs*0.5 0;
		margin: 0;
		font-family: $font-sans;
	}
	.contentpost__articlecontent {
		font-size: $rs*0.125*9;
		line-height: $rs*0.125*13;
		a {
			color: $color-black;
			text-decoration: underline;
			transition: color ease 0.3s;
			&:hover {
				color: #DD0000;
			}
		}
		::-moz-selection {
				color: #111;
				background: $color-secondary-light;
				text-shadow: none;
		}

		::selection {
				color: #111;
				background: $color-secondary-light;
				text-shadow: none;
		}

		h1, h2, h3,
		h4, h5, h6 {
				text-rendering: optimizeLegibility;
				margin: 0 0;
				padding: 0;
				font-family: $font-sans;
				font-weight: 800;
		}

		h1 {
				font-size: $rs*3;
				line-height: $rs*3;
				letter-spacing: -2px;
				text-indent: -3px;
				margin: $rs*0.125*15 0;
		}

		h2 {
				font-size: $rs*2;
				line-height: $rs*2;
				letter-spacing: -1px;
				text-indent: -2px;
				margin: $rs*0.125*15 0;
		}

		h3 {
				font-size: $rs*0.125*11;
				line-height: $rs*0.125*15;
		}

		h4 {
				font-size: $rs*0.125*11;
				line-height: $rs*0.125*15;
		}

		h5 {
				font-size: $rs*0.125*11;
				line-height: $rs*0.125*15;
		}

		h6 {
				font-size: $rs*0.125*11;
				line-height: $rs*0.125*15;
		}

		img {
			max-width: 100%;
		}
		p {
			padding: 0 0 $rs*0.125*13;
			margin: 0;
		}
		code {
			background-color: $color-white-dark;
			border-color: $color-white-light;
			font-family: Inconsolata, monospace, sans-serif;
			font-size: $rs;
		}
		pre > code {
			background-color: $color-white-light;
			border-color: $color-white-light;
			padding: 12px 24px;
			color: $color-black-light;
			font-size: 1.1rem;
			line-height: 1.5rem;
			display: block;
			white-space: pre;
			overflow: auto;
		}
		pre {
			padding: 0;
			margin: 0 0 2rem;
		}
		pre code, tt {
				white-space: -moz-pre-wrap;
				white-space: pre-wrap;
				border: none;
		}
		hr {
				border: 0;
				padding: 0;
				display: block;
				width: 20%;
				margin: $rs auto $rs*3 auto;
				border: 1px solid $color-white-dark;
		}

		blockquote {
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				margin: $rs - $rs $rs*2 - $rs;
				padding: 0 0;
				text-align: center;
				font-size: $rs*2.2;
				line-height: $rs*3;
				font-style: italic;
		}

		blockquote p {
				margin: 0.8em 0;
				font-style: italic;
		}

		blockquote small {
				display: inline-block;
				margin: 0.8em 0 0.8em 1.5em;;
				font-size:0.9em;
				color: #ccc;
		}
		kbd {
				display: inline-block;
				margin-bottom: $rs*0.5;
				padding: 1px 8px;
				border: #ccc 1px solid;
				color: $color-black-light;
				text-shadow: #fff 0 1px 0;
				font-size: 0.9em;
				font-weight: bold;
				background: $color-white-dark;
				border-radius: 4px;
				box-shadow:
						0 1px 0 rgba(0, 0, 0, 0.2),
						0 1px 0 0 #fff inset;
		}

		table {
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				margin: 0 0 $rs;
				width:100%;
				max-width: 100%;
				background-color: transparent;
		}

		table th,
		table td {
				padding: 8px;
				line-height: $rs*1.5;
				text-align: left;
				vertical-align: top;
				border-top: 1px solid $color-white-dark;
		}

		table th { color: #000; }

		table caption + thead tr:first-child th,
		table caption + thead tr:first-child td,
		table colgroup + thead tr:first-child th,
		table colgroup + thead tr:first-child td,
		table thead:first-child tr:first-child th,
		table thead:first-child tr:first-child td {
				border-top: 0;
		}

		table tbody + tbody { border-top: 2px solid #efefef; }

		table table table { background-color: #fff; }

		table tbody > tr:nth-child(odd) > td,
		table tbody > tr:nth-child(odd) > th {
				background-color: $color-white-dark;
		}

		table.plain {
				tbody > tr:nth-child(odd) > td,
				tbody > tr:nth-child(odd) > th {
						background: transparent;
				}
		}
	}
	.contentpost__footer {
		text-align: right;
		margin: $rs*4 0;
		a {
			color: $color-white-dark;
			font-size: $rs*1.4;
			margin: 0 0 0 $rs;
			transition: color ease 0.3s;
			&:hover {
				color: $color-main-dark;
			}
		}
	}
}

.footnotelist {
	font-size: 80%;
	border-top: 0.05rem solid #1e1e1e;
	margin: 0;
	padding-top: 10px;
}

.backlink {
	font-size: 75%;
}
